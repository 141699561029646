const rawData = [
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (af-ZA, AdriNeural)',
    DisplayName: 'Adri',
    LocalName: 'Adri',
    ShortName: 'af-ZA-AdriNeural',
    Gender: 'Female',
    Locale: 'af-ZA',
    LocaleName: 'Afrikaans (South Africa)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (af-ZA, WillemNeural)',
    DisplayName: 'Willem',
    LocalName: 'Willem',
    ShortName: 'af-ZA-WillemNeural',
    Gender: 'Male',
    Locale: 'af-ZA',
    LocaleName: 'Afrikaans (South Africa)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (am-ET, AmehaNeural)',
    DisplayName: 'Ameha',
    LocalName: 'አምሀ',
    ShortName: 'am-ET-AmehaNeural',
    Gender: 'Male',
    Locale: 'am-ET',
    LocaleName: 'Amharic (Ethiopia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (am-ET, MekdesNeural)',
    DisplayName: 'Mekdes',
    LocalName: 'መቅደስ',
    ShortName: 'am-ET-MekdesNeural',
    Gender: 'Female',
    Locale: 'am-ET',
    LocaleName: 'Amharic (Ethiopia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-AE, FatimaNeural)',
    DisplayName: 'Fatima',
    LocalName: 'فاطمة',
    ShortName: 'ar-AE-FatimaNeural',
    Gender: 'Female',
    Locale: 'ar-AE',
    LocaleName: 'Arabic (United Arab Emirates)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-AE, HamdanNeural)',
    DisplayName: 'Hamdan',
    LocalName: 'حمدان',
    ShortName: 'ar-AE-HamdanNeural',
    Gender: 'Male',
    Locale: 'ar-AE',
    LocaleName: 'Arabic (United Arab Emirates)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-BH, AliNeural)',
    DisplayName: 'Ali',
    LocalName: 'علي',
    ShortName: 'ar-BH-AliNeural',
    Gender: 'Male',
    Locale: 'ar-BH',
    LocaleName: 'Arabic (Bahrain)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-BH, LailaNeural)',
    DisplayName: 'Laila',
    LocalName: 'ليلى',
    ShortName: 'ar-BH-LailaNeural',
    Gender: 'Female',
    Locale: 'ar-BH',
    LocaleName: 'Arabic (Bahrain)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-DZ, AminaNeural)',
    DisplayName: 'Amina',
    LocalName: 'أمينة',
    ShortName: 'ar-DZ-AminaNeural',
    Gender: 'Female',
    Locale: 'ar-DZ',
    LocaleName: 'Arabic (Algeria)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-DZ, IsmaelNeural)',
    DisplayName: 'Ismael',
    LocalName: 'إسماعيل',
    ShortName: 'ar-DZ-IsmaelNeural',
    Gender: 'Male',
    Locale: 'ar-DZ',
    LocaleName: 'Arabic (Algeria)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-EG, SalmaNeural)',
    DisplayName: 'Salma',
    LocalName: 'سلمى',
    ShortName: 'ar-EG-SalmaNeural',
    Gender: 'Female',
    Locale: 'ar-EG',
    LocaleName: 'Arabic (Egypt)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-EG, ShakirNeural)',
    DisplayName: 'Shakir',
    LocalName: 'شاكر',
    ShortName: 'ar-EG-ShakirNeural',
    Gender: 'Male',
    Locale: 'ar-EG',
    LocaleName: 'Arabic (Egypt)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-IQ, BasselNeural)',
    DisplayName: 'Bassel',
    LocalName: 'باسل',
    ShortName: 'ar-IQ-BasselNeural',
    Gender: 'Male',
    Locale: 'ar-IQ',
    LocaleName: 'Arabic (Iraq)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-IQ, RanaNeural)',
    DisplayName: 'Rana',
    LocalName: 'رنا',
    ShortName: 'ar-IQ-RanaNeural',
    Gender: 'Female',
    Locale: 'ar-IQ',
    LocaleName: 'Arabic (Iraq)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-JO, SanaNeural)',
    DisplayName: 'Sana',
    LocalName: 'سناء',
    ShortName: 'ar-JO-SanaNeural',
    Gender: 'Female',
    Locale: 'ar-JO',
    LocaleName: 'Arabic (Jordan)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-JO, TaimNeural)',
    DisplayName: 'Taim',
    LocalName: 'تيم',
    ShortName: 'ar-JO-TaimNeural',
    Gender: 'Male',
    Locale: 'ar-JO',
    LocaleName: 'Arabic (Jordan)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-KW, FahedNeural)',
    DisplayName: 'Fahed',
    LocalName: 'فهد',
    ShortName: 'ar-KW-FahedNeural',
    Gender: 'Male',
    Locale: 'ar-KW',
    LocaleName: 'Arabic (Kuwait)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-KW, NouraNeural)',
    DisplayName: 'Noura',
    LocalName: 'نورا',
    ShortName: 'ar-KW-NouraNeural',
    Gender: 'Female',
    Locale: 'ar-KW',
    LocaleName: 'Arabic (Kuwait)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-LY, ImanNeural)',
    DisplayName: 'Iman',
    LocalName: 'إيمان',
    ShortName: 'ar-LY-ImanNeural',
    Gender: 'Female',
    Locale: 'ar-LY',
    LocaleName: 'Arabic (Libya)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-LY, OmarNeural)',
    DisplayName: 'Omar',
    LocalName: 'أحمد',
    ShortName: 'ar-LY-OmarNeural',
    Gender: 'Male',
    Locale: 'ar-LY',
    LocaleName: 'Arabic (Libya)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-MA, JamalNeural)',
    DisplayName: 'Jamal',
    LocalName: 'جمال',
    ShortName: 'ar-MA-JamalNeural',
    Gender: 'Male',
    Locale: 'ar-MA',
    LocaleName: 'Arabic (Morocco)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-MA, MounaNeural)',
    DisplayName: 'Mouna',
    LocalName: 'منى',
    ShortName: 'ar-MA-MounaNeural',
    Gender: 'Female',
    Locale: 'ar-MA',
    LocaleName: 'Arabic (Morocco)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-QA, AmalNeural)',
    DisplayName: 'Amal',
    LocalName: 'أمل',
    ShortName: 'ar-QA-AmalNeural',
    Gender: 'Female',
    Locale: 'ar-QA',
    LocaleName: 'Arabic (Qatar)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-QA, MoazNeural)',
    DisplayName: 'Moaz',
    LocalName: 'معاذ',
    ShortName: 'ar-QA-MoazNeural',
    Gender: 'Male',
    Locale: 'ar-QA',
    LocaleName: 'Arabic (Qatar)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-SA, HamedNeural)',
    DisplayName: 'Hamed',
    LocalName: 'حامد',
    ShortName: 'ar-SA-HamedNeural',
    Gender: 'Male',
    Locale: 'ar-SA',
    LocaleName: 'Arabic (Saudi Arabia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-SA, ZariyahNeural)',
    DisplayName: 'Zariyah',
    LocalName: 'زارية',
    ShortName: 'ar-SA-ZariyahNeural',
    Gender: 'Female',
    Locale: 'ar-SA',
    LocaleName: 'Arabic (Saudi Arabia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-SY, AmanyNeural)',
    DisplayName: 'Amany',
    LocalName: 'أماني',
    ShortName: 'ar-SY-AmanyNeural',
    Gender: 'Female',
    Locale: 'ar-SY',
    LocaleName: 'Arabic (Syria)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-SY, LaithNeural)',
    DisplayName: 'Laith',
    LocalName: 'ليث',
    ShortName: 'ar-SY-LaithNeural',
    Gender: 'Male',
    Locale: 'ar-SY',
    LocaleName: 'Arabic (Syria)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-TN, HediNeural)',
    DisplayName: 'Hedi',
    LocalName: 'هادي',
    ShortName: 'ar-TN-HediNeural',
    Gender: 'Male',
    Locale: 'ar-TN',
    LocaleName: 'Arabic (Tunisia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-TN, ReemNeural)',
    DisplayName: 'Reem',
    LocalName: 'ريم',
    ShortName: 'ar-TN-ReemNeural',
    Gender: 'Female',
    Locale: 'ar-TN',
    LocaleName: 'Arabic (Tunisia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-YE, MaryamNeural)',
    DisplayName: 'Maryam',
    LocalName: 'مريم',
    ShortName: 'ar-YE-MaryamNeural',
    Gender: 'Female',
    Locale: 'ar-YE',
    LocaleName: 'Arabic (Yemen)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ar-YE, SalehNeural)',
    DisplayName: 'Saleh',
    LocalName: 'صالح',
    ShortName: 'ar-YE-SalehNeural',
    Gender: 'Male',
    Locale: 'ar-YE',
    LocaleName: 'Arabic (Yemen)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (bg-BG, BorislavNeural)',
    DisplayName: 'Borislav',
    LocalName: 'Борислав',
    ShortName: 'bg-BG-BorislavNeural',
    Gender: 'Male',
    Locale: 'bg-BG',
    LocaleName: 'Bulgarian (Bulgaria)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (bg-BG, KalinaNeural)',
    DisplayName: 'Kalina',
    LocalName: 'Калина',
    ShortName: 'bg-BG-KalinaNeural',
    Gender: 'Female',
    Locale: 'bg-BG',
    LocaleName: 'Bulgarian (Bulgaria)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (bn-BD, NabanitaNeural)',
    DisplayName: 'Nabanita',
    LocalName: 'নবনীতা',
    ShortName: 'bn-BD-NabanitaNeural',
    Gender: 'Female',
    Locale: 'bn-BD',
    LocaleName: 'Bangla (Bangladesh)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (bn-BD, PradeepNeural)',
    DisplayName: 'Pradeep',
    LocalName: 'প্রদ্বীপ',
    ShortName: 'bn-BD-PradeepNeural',
    Gender: 'Male',
    Locale: 'bn-BD',
    LocaleName: 'Bangla (Bangladesh)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ca-ES, JoanaNeural)',
    DisplayName: 'Joana',
    LocalName: 'Joana',
    ShortName: 'ca-ES-JoanaNeural',
    Gender: 'Female',
    Locale: 'ca-ES',
    LocaleName: 'Catalan (Spain)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ca-ES, AlbaNeural)',
    DisplayName: 'Alba',
    LocalName: 'Alba',
    ShortName: 'ca-ES-AlbaNeural',
    Gender: 'Female',
    Locale: 'ca-ES',
    LocaleName: 'Catalan (Spain)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ca-ES, EnricNeural)',
    DisplayName: 'Enric',
    LocalName: 'Enric',
    ShortName: 'ca-ES-EnricNeural',
    Gender: 'Male',
    Locale: 'ca-ES',
    LocaleName: 'Catalan (Spain)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (cs-CZ, AntoninNeural)',
    DisplayName: 'Antonin',
    LocalName: 'Antonín',
    ShortName: 'cs-CZ-AntoninNeural',
    Gender: 'Male',
    Locale: 'cs-CZ',
    LocaleName: 'Czech (Czech)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (cs-CZ, VlastaNeural)',
    DisplayName: 'Vlasta',
    LocalName: 'Vlasta',
    ShortName: 'cs-CZ-VlastaNeural',
    Gender: 'Female',
    Locale: 'cs-CZ',
    LocaleName: 'Czech (Czech)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (cy-GB, AledNeural)',
    DisplayName: 'Aled',
    LocalName: 'Aled',
    ShortName: 'cy-GB-AledNeural',
    Gender: 'Male',
    Locale: 'cy-GB',
    LocaleName: 'Welsh (United Kingdom)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (cy-GB, NiaNeural)',
    DisplayName: 'Nia',
    LocalName: 'Nia',
    ShortName: 'cy-GB-NiaNeural',
    Gender: 'Female',
    Locale: 'cy-GB',
    LocaleName: 'Welsh (United Kingdom)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (da-DK, ChristelNeural)',
    DisplayName: 'Christel',
    LocalName: 'Christel',
    ShortName: 'da-DK-ChristelNeural',
    Gender: 'Female',
    Locale: 'da-DK',
    LocaleName: 'Danish (Denmark)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (da-DK, JeppeNeural)',
    DisplayName: 'Jeppe',
    LocalName: 'Jeppe',
    ShortName: 'da-DK-JeppeNeural',
    Gender: 'Male',
    Locale: 'da-DK',
    LocaleName: 'Danish (Denmark)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (de-AT, IngridNeural)',
    DisplayName: 'Ingrid',
    LocalName: 'Ingrid',
    ShortName: 'de-AT-IngridNeural',
    Gender: 'Female',
    Locale: 'de-AT',
    LocaleName: 'German (Austria)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (de-AT, JonasNeural)',
    DisplayName: 'Jonas',
    LocalName: 'Jonas',
    ShortName: 'de-AT-JonasNeural',
    Gender: 'Male',
    Locale: 'de-AT',
    LocaleName: 'German (Austria)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (de-CH, JanNeural)',
    DisplayName: 'Jan',
    LocalName: 'Jan',
    ShortName: 'de-CH-JanNeural',
    Gender: 'Male',
    Locale: 'de-CH',
    LocaleName: 'German (Switzerland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (de-CH, LeniNeural)',
    DisplayName: 'Leni',
    LocalName: 'Leni',
    ShortName: 'de-CH-LeniNeural',
    Gender: 'Female',
    Locale: 'de-CH',
    LocaleName: 'German (Switzerland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (de-DE, KatjaNeural)',
    DisplayName: 'Katja',
    LocalName: 'Katja',
    ShortName: 'de-DE-KatjaNeural',
    Gender: 'Female',
    Locale: 'de-DE',
    LocaleName: 'German (Germany)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (de-DE, ConradNeural)',
    DisplayName: 'Conrad',
    LocalName: 'Conrad',
    ShortName: 'de-DE-ConradNeural',
    Gender: 'Male',
    Locale: 'de-DE',
    LocaleName: 'German (Germany)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (el-GR, AthinaNeural)',
    DisplayName: 'Athina',
    LocalName: 'Αθηνά',
    ShortName: 'el-GR-AthinaNeural',
    Gender: 'Female',
    Locale: 'el-GR',
    LocaleName: 'Greek (Greece)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (el-GR, NestorasNeural)',
    DisplayName: 'Nestoras',
    LocalName: 'Νέστορας',
    ShortName: 'el-GR-NestorasNeural',
    Gender: 'Male',
    Locale: 'el-GR',
    LocaleName: 'Greek (Greece)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-AU, NatashaNeural)',
    DisplayName: 'Natasha',
    LocalName: 'Natasha',
    ShortName: 'en-AU-NatashaNeural',
    Gender: 'Female',
    Locale: 'en-AU',
    LocaleName: 'English (Australia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-AU, WilliamNeural)',
    DisplayName: 'William',
    LocalName: 'William',
    ShortName: 'en-AU-WilliamNeural',
    Gender: 'Male',
    Locale: 'en-AU',
    LocaleName: 'English (Australia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-CA, ClaraNeural)',
    DisplayName: 'Clara',
    LocalName: 'Clara',
    ShortName: 'en-CA-ClaraNeural',
    Gender: 'Female',
    Locale: 'en-CA',
    LocaleName: 'English (Canada)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-CA, LiamNeural)',
    DisplayName: 'Liam',
    LocalName: 'Liam',
    ShortName: 'en-CA-LiamNeural',
    Gender: 'Male',
    Locale: 'en-CA',
    LocaleName: 'English (Canada)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-GB, LibbyNeural)',
    DisplayName: 'Libby',
    LocalName: 'Libby',
    ShortName: 'en-GB-LibbyNeural',
    Gender: 'Female',
    Locale: 'en-GB',
    LocaleName: 'English (United Kingdom)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-GB, RyanNeural)',
    DisplayName: 'Ryan',
    LocalName: 'Ryan',
    ShortName: 'en-GB-RyanNeural',
    Gender: 'Male',
    Locale: 'en-GB',
    LocaleName: 'English (United Kingdom)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-GB, SoniaNeural)',
    DisplayName: 'Sonia',
    LocalName: 'Sonia',
    ShortName: 'en-GB-SoniaNeural',
    Gender: 'Female',
    Locale: 'en-GB',
    LocaleName: 'English (United Kingdom)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-GB, MiaNeural)',
    DisplayName: 'Mia',
    LocalName: 'Mia',
    ShortName: 'en-GB-MiaNeural',
    Gender: 'Female',
    Locale: 'en-GB',
    LocaleName: 'English (United Kingdom)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'Deprecated'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-HK, SamNeural)',
    DisplayName: 'Sam',
    LocalName: 'Sam',
    ShortName: 'en-HK-SamNeural',
    Gender: 'Male',
    Locale: 'en-HK',
    LocaleName: 'English (Hongkong)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-HK, YanNeural)',
    DisplayName: 'Yan',
    LocalName: 'Yan',
    ShortName: 'en-HK-YanNeural',
    Gender: 'Female',
    Locale: 'en-HK',
    LocaleName: 'English (Hongkong)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-IE, ConnorNeural)',
    DisplayName: 'Connor',
    LocalName: 'Connor',
    ShortName: 'en-IE-ConnorNeural',
    Gender: 'Male',
    Locale: 'en-IE',
    LocaleName: 'English (Ireland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-IE, EmilyNeural)',
    DisplayName: 'Emily',
    LocalName: 'Emily',
    ShortName: 'en-IE-EmilyNeural',
    Gender: 'Female',
    Locale: 'en-IE',
    LocaleName: 'English (Ireland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-IN, NeerjaNeural)',
    DisplayName: 'Neerja',
    LocalName: 'Neerja',
    ShortName: 'en-IN-NeerjaNeural',
    Gender: 'Female',
    Locale: 'en-IN',
    LocaleName: 'English (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-IN, PrabhatNeural)',
    DisplayName: 'Prabhat',
    LocalName: 'Prabhat',
    ShortName: 'en-IN-PrabhatNeural',
    Gender: 'Male',
    Locale: 'en-IN',
    LocaleName: 'English (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-KE, AsiliaNeural)',
    DisplayName: 'Asilia',
    LocalName: 'Asilia',
    ShortName: 'en-KE-AsiliaNeural',
    Gender: 'Female',
    Locale: 'en-KE',
    LocaleName: 'English (Kenya)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-KE, ChilembaNeural)',
    DisplayName: 'Chilemba',
    LocalName: 'Chilemba',
    ShortName: 'en-KE-ChilembaNeural',
    Gender: 'Male',
    Locale: 'en-KE',
    LocaleName: 'English (Kenya)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-NG, AbeoNeural)',
    DisplayName: 'Abeo',
    LocalName: 'Abeo',
    ShortName: 'en-NG-AbeoNeural',
    Gender: 'Male',
    Locale: 'en-NG',
    LocaleName: 'English (Nigeria)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-NG, EzinneNeural)',
    DisplayName: 'Ezinne',
    LocalName: 'Ezinne',
    ShortName: 'en-NG-EzinneNeural',
    Gender: 'Female',
    Locale: 'en-NG',
    LocaleName: 'English (Nigeria)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-NZ, MitchellNeural)',
    DisplayName: 'Mitchell',
    LocalName: 'Mitchell',
    ShortName: 'en-NZ-MitchellNeural',
    Gender: 'Male',
    Locale: 'en-NZ',
    LocaleName: 'English (New Zealand)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-NZ, MollyNeural)',
    DisplayName: 'Molly',
    LocalName: 'Molly',
    ShortName: 'en-NZ-MollyNeural',
    Gender: 'Female',
    Locale: 'en-NZ',
    LocaleName: 'English (New Zealand)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-PH, JamesNeural)',
    DisplayName: 'James',
    LocalName: 'James',
    ShortName: 'en-PH-JamesNeural',
    Gender: 'Male',
    Locale: 'en-PH',
    LocaleName: 'English (Philippines)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-PH, RosaNeural)',
    DisplayName: 'Rosa',
    LocalName: 'Rosa',
    ShortName: 'en-PH-RosaNeural',
    Gender: 'Female',
    Locale: 'en-PH',
    LocaleName: 'English (Philippines)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-SG, LunaNeural)',
    DisplayName: 'Luna',
    LocalName: 'Luna',
    ShortName: 'en-SG-LunaNeural',
    Gender: 'Female',
    Locale: 'en-SG',
    LocaleName: 'English (Singapore)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-SG, WayneNeural)',
    DisplayName: 'Wayne',
    LocalName: 'Wayne',
    ShortName: 'en-SG-WayneNeural',
    Gender: 'Male',
    Locale: 'en-SG',
    LocaleName: 'English (Singapore)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-TZ, ElimuNeural)',
    DisplayName: 'Elimu',
    LocalName: 'Elimu',
    ShortName: 'en-TZ-ElimuNeural',
    Gender: 'Male',
    Locale: 'en-TZ',
    LocaleName: 'English (Tanzania)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-TZ, ImaniNeural)',
    DisplayName: 'Imani',
    LocalName: 'Imani',
    ShortName: 'en-TZ-ImaniNeural',
    Gender: 'Female',
    Locale: 'en-TZ',
    LocaleName: 'English (Tanzania)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, JennyNeural)',
    DisplayName: 'Jenny',
    LocalName: 'Jenny',
    ShortName: 'en-US-JennyNeural',
    Gender: 'Female',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    StyleList: [
      'assistant',
      'chat',
      'customerservice',
      'newscast'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, JennyMultilingualNeural)',
    DisplayName: 'Jenny Multilingual',
    LocalName: 'Jenny Multilingual',
    ShortName: 'en-US-JennyMultilingualNeural',
    Gender: 'Female',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SecondaryLocaleList: [
      'de-DE',
      'en-AU',
      'en-CA',
      'en-GB',
      'es-ES',
      'es-MX',
      'fr-CA',
      'fr-FR',
      'it-IT',
      'ja-JP',
      'ko-KR',
      'pt-BR',
      'zh-CN'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'Preview'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, GuyNeural)',
    DisplayName: 'Guy',
    LocalName: 'Guy',
    ShortName: 'en-US-GuyNeural',
    Gender: 'Male',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    StyleList: [
      'newscast'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, AmberNeural)',
    DisplayName: 'Amber',
    LocalName: 'Amber',
    ShortName: 'en-US-AmberNeural',
    Gender: 'Female',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)',
    DisplayName: 'Ana',
    LocalName: 'Ana',
    ShortName: 'en-US-AnaNeural',
    Gender: 'Female',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, AriaNeural)',
    DisplayName: 'Aria',
    LocalName: 'Aria',
    ShortName: 'en-US-AriaNeural',
    Gender: 'Female',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    StyleList: [
      'chat',
      'customerservice',
      'narration-professional',
      'newscast-casual',
      'newscast-formal',
      'cheerful',
      'empathetic'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, AshleyNeural)',
    DisplayName: 'Ashley',
    LocalName: 'Ashley',
    ShortName: 'en-US-AshleyNeural',
    Gender: 'Female',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, BrandonNeural)',
    DisplayName: 'Brandon',
    LocalName: 'Brandon',
    ShortName: 'en-US-BrandonNeural',
    Gender: 'Male',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, ChristopherNeural)',
    DisplayName: 'Christopher',
    LocalName: 'Christopher',
    ShortName: 'en-US-ChristopherNeural',
    Gender: 'Male',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, CoraNeural)',
    DisplayName: 'Cora',
    LocalName: 'Cora',
    ShortName: 'en-US-CoraNeural',
    Gender: 'Female',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, ElizabethNeural)',
    DisplayName: 'Elizabeth',
    LocalName: 'Elizabeth',
    ShortName: 'en-US-ElizabethNeural',
    Gender: 'Female',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, EricNeural)',
    DisplayName: 'Eric',
    LocalName: 'Eric',
    ShortName: 'en-US-EricNeural',
    Gender: 'Male',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, JacobNeural)',
    DisplayName: 'Jacob',
    LocalName: 'Jacob',
    ShortName: 'en-US-JacobNeural',
    Gender: 'Male',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, MichelleNeural)',
    DisplayName: 'Michelle',
    LocalName: 'Michelle',
    ShortName: 'en-US-MichelleNeural',
    Gender: 'Female',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, MonicaNeural)',
    DisplayName: 'Monica',
    LocalName: 'Monica',
    ShortName: 'en-US-MonicaNeural',
    Gender: 'Female',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-US, SaraNeural)',
    DisplayName: 'Sara',
    LocalName: 'Sara',
    ShortName: 'en-US-SaraNeural',
    Gender: 'Female',
    Locale: 'en-US',
    LocaleName: 'English (United States)',
    StyleList: [
      'cheerful',
      'angry',
      'sad'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-ZA, LeahNeural)',
    DisplayName: 'Leah',
    LocalName: 'Leah',
    ShortName: 'en-ZA-LeahNeural',
    Gender: 'Female',
    Locale: 'en-ZA',
    LocaleName: 'English (South Africa)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (en-ZA, LukeNeural)',
    DisplayName: 'Luke',
    LocalName: 'Luke',
    ShortName: 'en-ZA-LukeNeural',
    Gender: 'Male',
    Locale: 'en-ZA',
    LocaleName: 'English (South Africa)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-AR, ElenaNeural)',
    DisplayName: 'Elena',
    LocalName: 'Elena',
    ShortName: 'es-AR-ElenaNeural',
    Gender: 'Female',
    Locale: 'es-AR',
    LocaleName: 'Spanish (Argentina)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-AR, TomasNeural)',
    DisplayName: 'Tomas',
    LocalName: 'Tomas',
    ShortName: 'es-AR-TomasNeural',
    Gender: 'Male',
    Locale: 'es-AR',
    LocaleName: 'Spanish (Argentina)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-BO, MarceloNeural)',
    DisplayName: 'Marcelo',
    LocalName: 'Marcelo',
    ShortName: 'es-BO-MarceloNeural',
    Gender: 'Male',
    Locale: 'es-BO',
    LocaleName: 'Spanish (Bolivia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-BO, SofiaNeural)',
    DisplayName: 'Sofia',
    LocalName: 'Sofia',
    ShortName: 'es-BO-SofiaNeural',
    Gender: 'Female',
    Locale: 'es-BO',
    LocaleName: 'Spanish (Bolivia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-CL, CatalinaNeural)',
    DisplayName: 'Catalina',
    LocalName: 'Catalina',
    ShortName: 'es-CL-CatalinaNeural',
    Gender: 'Female',
    Locale: 'es-CL',
    LocaleName: 'Spanish (Chile)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-CL, LorenzoNeural)',
    DisplayName: 'Lorenzo',
    LocalName: 'Lorenzo',
    ShortName: 'es-CL-LorenzoNeural',
    Gender: 'Male',
    Locale: 'es-CL',
    LocaleName: 'Spanish (Chile)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-CO, GonzaloNeural)',
    DisplayName: 'Gonzalo',
    LocalName: 'Gonzalo',
    ShortName: 'es-CO-GonzaloNeural',
    Gender: 'Male',
    Locale: 'es-CO',
    LocaleName: 'Spanish (Colombia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-CO, SalomeNeural)',
    DisplayName: 'Salome',
    LocalName: 'Salome',
    ShortName: 'es-CO-SalomeNeural',
    Gender: 'Female',
    Locale: 'es-CO',
    LocaleName: 'Spanish (Colombia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-CR, JuanNeural)',
    DisplayName: 'Juan',
    LocalName: 'Juan',
    ShortName: 'es-CR-JuanNeural',
    Gender: 'Male',
    Locale: 'es-CR',
    LocaleName: 'Spanish (Costa Rica)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-CR, MariaNeural)',
    DisplayName: 'Maria',
    LocalName: 'María',
    ShortName: 'es-CR-MariaNeural',
    Gender: 'Female',
    Locale: 'es-CR',
    LocaleName: 'Spanish (Costa Rica)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-CU, BelkysNeural)',
    DisplayName: 'Belkys',
    LocalName: 'Belkys',
    ShortName: 'es-CU-BelkysNeural',
    Gender: 'Female',
    Locale: 'es-CU',
    LocaleName: 'Spanish (Cuba)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-CU, ManuelNeural)',
    DisplayName: 'Manuel',
    LocalName: 'Manuel',
    ShortName: 'es-CU-ManuelNeural',
    Gender: 'Male',
    Locale: 'es-CU',
    LocaleName: 'Spanish (Cuba)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-DO, EmilioNeural)',
    DisplayName: 'Emilio',
    LocalName: 'Emilio',
    ShortName: 'es-DO-EmilioNeural',
    Gender: 'Male',
    Locale: 'es-DO',
    LocaleName: 'Spanish (Dominican Republic)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-DO, RamonaNeural)',
    DisplayName: 'Ramona',
    LocalName: 'Ramona',
    ShortName: 'es-DO-RamonaNeural',
    Gender: 'Female',
    Locale: 'es-DO',
    LocaleName: 'Spanish (Dominican Republic)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-EC, AndreaNeural)',
    DisplayName: 'Andrea',
    LocalName: 'Andrea',
    ShortName: 'es-EC-AndreaNeural',
    Gender: 'Female',
    Locale: 'es-EC',
    LocaleName: 'Spanish (Ecuador)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-EC, LuisNeural)',
    DisplayName: 'Luis',
    LocalName: 'Luis',
    ShortName: 'es-EC-LuisNeural',
    Gender: 'Male',
    Locale: 'es-EC',
    LocaleName: 'Spanish (Ecuador)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-ES, AlvaroNeural)',
    DisplayName: 'Alvaro',
    LocalName: 'Álvaro',
    ShortName: 'es-ES-AlvaroNeural',
    Gender: 'Male',
    Locale: 'es-ES',
    LocaleName: 'Spanish (Spain)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-ES, ElviraNeural)',
    DisplayName: 'Elvira',
    LocalName: 'Elvira',
    ShortName: 'es-ES-ElviraNeural',
    Gender: 'Female',
    Locale: 'es-ES',
    LocaleName: 'Spanish (Spain)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-GQ, JavierNeural)',
    DisplayName: 'Javier',
    LocalName: 'Javier',
    ShortName: 'es-GQ-JavierNeural',
    Gender: 'Male',
    Locale: 'es-GQ',
    LocaleName: 'Spanish (Equatorial Guinea)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-GQ, TeresaNeural)',
    DisplayName: 'Teresa',
    LocalName: 'Teresa',
    ShortName: 'es-GQ-TeresaNeural',
    Gender: 'Female',
    Locale: 'es-GQ',
    LocaleName: 'Spanish (Equatorial Guinea)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-GT, AndresNeural)',
    DisplayName: 'Andres',
    LocalName: 'Andrés',
    ShortName: 'es-GT-AndresNeural',
    Gender: 'Male',
    Locale: 'es-GT',
    LocaleName: 'Spanish (Guatemala)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-GT, MartaNeural)',
    DisplayName: 'Marta',
    LocalName: 'Marta',
    ShortName: 'es-GT-MartaNeural',
    Gender: 'Female',
    Locale: 'es-GT',
    LocaleName: 'Spanish (Guatemala)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-HN, CarlosNeural)',
    DisplayName: 'Carlos',
    LocalName: 'Carlos',
    ShortName: 'es-HN-CarlosNeural',
    Gender: 'Male',
    Locale: 'es-HN',
    LocaleName: 'Spanish (Honduras)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-HN, KarlaNeural)',
    DisplayName: 'Karla',
    LocalName: 'Karla',
    ShortName: 'es-HN-KarlaNeural',
    Gender: 'Female',
    Locale: 'es-HN',
    LocaleName: 'Spanish (Honduras)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-MX, DaliaNeural)',
    DisplayName: 'Dalia',
    LocalName: 'Dalia',
    ShortName: 'es-MX-DaliaNeural',
    Gender: 'Female',
    Locale: 'es-MX',
    LocaleName: 'Spanish (Mexico)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-MX, JorgeNeural)',
    DisplayName: 'Jorge',
    LocalName: 'Jorge',
    ShortName: 'es-MX-JorgeNeural',
    Gender: 'Male',
    Locale: 'es-MX',
    LocaleName: 'Spanish (Mexico)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-NI, FedericoNeural)',
    DisplayName: 'Federico',
    LocalName: 'Federico',
    ShortName: 'es-NI-FedericoNeural',
    Gender: 'Male',
    Locale: 'es-NI',
    LocaleName: 'Spanish (Nicaragua)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-NI, YolandaNeural)',
    DisplayName: 'Yolanda',
    LocalName: 'Yolanda',
    ShortName: 'es-NI-YolandaNeural',
    Gender: 'Female',
    Locale: 'es-NI',
    LocaleName: 'Spanish (Nicaragua)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-PA, MargaritaNeural)',
    DisplayName: 'Margarita',
    LocalName: 'Margarita',
    ShortName: 'es-PA-MargaritaNeural',
    Gender: 'Female',
    Locale: 'es-PA',
    LocaleName: 'Spanish (Panama)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-PA, RobertoNeural)',
    DisplayName: 'Roberto',
    LocalName: 'Roberto',
    ShortName: 'es-PA-RobertoNeural',
    Gender: 'Male',
    Locale: 'es-PA',
    LocaleName: 'Spanish (Panama)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-PE, AlexNeural)',
    DisplayName: 'Alex',
    LocalName: 'Alex',
    ShortName: 'es-PE-AlexNeural',
    Gender: 'Male',
    Locale: 'es-PE',
    LocaleName: 'Spanish (Peru)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-PE, CamilaNeural)',
    DisplayName: 'Camila',
    LocalName: 'Camila',
    ShortName: 'es-PE-CamilaNeural',
    Gender: 'Female',
    Locale: 'es-PE',
    LocaleName: 'Spanish (Peru)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-PR, KarinaNeural)',
    DisplayName: 'Karina',
    LocalName: 'Karina',
    ShortName: 'es-PR-KarinaNeural',
    Gender: 'Female',
    Locale: 'es-PR',
    LocaleName: 'Spanish (Puerto Rico)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-PR, VictorNeural)',
    DisplayName: 'Victor',
    LocalName: 'Víctor',
    ShortName: 'es-PR-VictorNeural',
    Gender: 'Male',
    Locale: 'es-PR',
    LocaleName: 'Spanish (Puerto Rico)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-PY, MarioNeural)',
    DisplayName: 'Mario',
    LocalName: 'Mario',
    ShortName: 'es-PY-MarioNeural',
    Gender: 'Male',
    Locale: 'es-PY',
    LocaleName: 'Spanish (Paraguay)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-PY, TaniaNeural)',
    DisplayName: 'Tania',
    LocalName: 'Tania',
    ShortName: 'es-PY-TaniaNeural',
    Gender: 'Female',
    Locale: 'es-PY',
    LocaleName: 'Spanish (Paraguay)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-SV, LorenaNeural)',
    DisplayName: 'Lorena',
    LocalName: 'Lorena',
    ShortName: 'es-SV-LorenaNeural',
    Gender: 'Female',
    Locale: 'es-SV',
    LocaleName: 'Spanish (El Salvador)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-SV, RodrigoNeural)',
    DisplayName: 'Rodrigo',
    LocalName: 'Rodrigo',
    ShortName: 'es-SV-RodrigoNeural',
    Gender: 'Male',
    Locale: 'es-SV',
    LocaleName: 'Spanish (El Salvador)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-US, AlonsoNeural)',
    DisplayName: 'Alonso',
    LocalName: 'Alonso',
    ShortName: 'es-US-AlonsoNeural',
    Gender: 'Male',
    Locale: 'es-US',
    LocaleName: 'Spanish (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-US, PalomaNeural)',
    DisplayName: 'Paloma',
    LocalName: 'Paloma',
    ShortName: 'es-US-PalomaNeural',
    Gender: 'Female',
    Locale: 'es-US',
    LocaleName: 'Spanish (United States)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-UY, MateoNeural)',
    DisplayName: 'Mateo',
    LocalName: 'Mateo',
    ShortName: 'es-UY-MateoNeural',
    Gender: 'Male',
    Locale: 'es-UY',
    LocaleName: 'Spanish (Uruguay)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-UY, ValentinaNeural)',
    DisplayName: 'Valentina',
    LocalName: 'Valentina',
    ShortName: 'es-UY-ValentinaNeural',
    Gender: 'Female',
    Locale: 'es-UY',
    LocaleName: 'Spanish (Uruguay)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-VE, PaolaNeural)',
    DisplayName: 'Paola',
    LocalName: 'Paola',
    ShortName: 'es-VE-PaolaNeural',
    Gender: 'Female',
    Locale: 'es-VE',
    LocaleName: 'Spanish (Venezuela)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (es-VE, SebastianNeural)',
    DisplayName: 'Sebastian',
    LocalName: 'Sebastián',
    ShortName: 'es-VE-SebastianNeural',
    Gender: 'Male',
    Locale: 'es-VE',
    LocaleName: 'Spanish (Venezuela)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (et-EE, AnuNeural)',
    DisplayName: 'Anu',
    LocalName: 'Anu',
    ShortName: 'et-EE-AnuNeural',
    Gender: 'Female',
    Locale: 'et-EE',
    LocaleName: 'Estonian (Estonia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (et-EE, KertNeural)',
    DisplayName: 'Kert',
    LocalName: 'Kert',
    ShortName: 'et-EE-KertNeural',
    Gender: 'Male',
    Locale: 'et-EE',
    LocaleName: 'Estonian (Estonia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fa-IR, DilaraNeural)',
    DisplayName: 'Dilara',
    LocalName: 'دلارا',
    ShortName: 'fa-IR-DilaraNeural',
    Gender: 'Female',
    Locale: 'fa-IR',
    LocaleName: 'Persian (Iran)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fa-IR, FaridNeural)',
    DisplayName: 'Farid',
    LocalName: 'فرید',
    ShortName: 'fa-IR-FaridNeural',
    Gender: 'Male',
    Locale: 'fa-IR',
    LocaleName: 'Persian (Iran)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fi-FI, SelmaNeural)',
    DisplayName: 'Selma',
    LocalName: 'Selma',
    ShortName: 'fi-FI-SelmaNeural',
    Gender: 'Female',
    Locale: 'fi-FI',
    LocaleName: 'Finnish (Finland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fi-FI, HarriNeural)',
    DisplayName: 'Harri',
    LocalName: 'Harri',
    ShortName: 'fi-FI-HarriNeural',
    Gender: 'Male',
    Locale: 'fi-FI',
    LocaleName: 'Finnish (Finland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fi-FI, NooraNeural)',
    DisplayName: 'Noora',
    LocalName: 'Noora',
    ShortName: 'fi-FI-NooraNeural',
    Gender: 'Female',
    Locale: 'fi-FI',
    LocaleName: 'Finnish (Finland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fil-PH, AngeloNeural)',
    DisplayName: 'Angelo',
    LocalName: 'Angelo',
    ShortName: 'fil-PH-AngeloNeural',
    Gender: 'Male',
    Locale: 'fil-PH',
    LocaleName: 'Filipino (Philippines)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fil-PH, BlessicaNeural)',
    DisplayName: 'Blessica',
    LocalName: 'Blessica',
    ShortName: 'fil-PH-BlessicaNeural',
    Gender: 'Female',
    Locale: 'fil-PH',
    LocaleName: 'Filipino (Philippines)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fr-BE, CharlineNeural)',
    DisplayName: 'Charline',
    LocalName: 'Charline',
    ShortName: 'fr-BE-CharlineNeural',
    Gender: 'Female',
    Locale: 'fr-BE',
    LocaleName: 'French (Belgium)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fr-BE, GerardNeural)',
    DisplayName: 'Gerard',
    LocalName: 'Gerard',
    ShortName: 'fr-BE-GerardNeural',
    Gender: 'Male',
    Locale: 'fr-BE',
    LocaleName: 'French (Belgium)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fr-CA, SylvieNeural)',
    DisplayName: 'Sylvie',
    LocalName: 'Sylvie',
    ShortName: 'fr-CA-SylvieNeural',
    Gender: 'Female',
    Locale: 'fr-CA',
    LocaleName: 'French (Canada)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fr-CA, AntoineNeural)',
    DisplayName: 'Antoine',
    LocalName: 'Antoine',
    ShortName: 'fr-CA-AntoineNeural',
    Gender: 'Male',
    Locale: 'fr-CA',
    LocaleName: 'French (Canada)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fr-CA, JeanNeural)',
    DisplayName: 'Jean',
    LocalName: 'Jean',
    ShortName: 'fr-CA-JeanNeural',
    Gender: 'Male',
    Locale: 'fr-CA',
    LocaleName: 'French (Canada)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fr-CH, ArianeNeural)',
    DisplayName: 'Ariane',
    LocalName: 'Ariane',
    ShortName: 'fr-CH-ArianeNeural',
    Gender: 'Female',
    Locale: 'fr-CH',
    LocaleName: 'French (Switzerland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fr-CH, FabriceNeural)',
    DisplayName: 'Fabrice',
    LocalName: 'Fabrice',
    ShortName: 'fr-CH-FabriceNeural',
    Gender: 'Male',
    Locale: 'fr-CH',
    LocaleName: 'French (Switzerland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fr-FR, DeniseNeural)',
    DisplayName: 'Denise',
    LocalName: 'Denise',
    ShortName: 'fr-FR-DeniseNeural',
    Gender: 'Female',
    Locale: 'fr-FR',
    LocaleName: 'French (France)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (fr-FR, HenriNeural)',
    DisplayName: 'Henri',
    LocalName: 'Henri',
    ShortName: 'fr-FR-HenriNeural',
    Gender: 'Male',
    Locale: 'fr-FR',
    LocaleName: 'French (France)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ga-IE, ColmNeural)',
    DisplayName: 'Colm',
    LocalName: 'Colm',
    ShortName: 'ga-IE-ColmNeural',
    Gender: 'Male',
    Locale: 'ga-IE',
    LocaleName: 'Irish (Ireland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ga-IE, OrlaNeural)',
    DisplayName: 'Orla',
    LocalName: 'Orla',
    ShortName: 'ga-IE-OrlaNeural',
    Gender: 'Female',
    Locale: 'ga-IE',
    LocaleName: 'Irish (Ireland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (gl-ES, RoiNeural)',
    DisplayName: 'Roi',
    LocalName: 'Roi',
    ShortName: 'gl-ES-RoiNeural',
    Gender: 'Male',
    Locale: 'gl-ES',
    LocaleName: 'Galician (Spain)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (gl-ES, SabelaNeural)',
    DisplayName: 'Sabela',
    LocalName: 'Sabela',
    ShortName: 'gl-ES-SabelaNeural',
    Gender: 'Female',
    Locale: 'gl-ES',
    LocaleName: 'Galician (Spain)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (gu-IN, DhwaniNeural)',
    DisplayName: 'Dhwani',
    LocalName: 'ધ્વની',
    ShortName: 'gu-IN-DhwaniNeural',
    Gender: 'Female',
    Locale: 'gu-IN',
    LocaleName: 'Gujarati (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (gu-IN, NiranjanNeural)',
    DisplayName: 'Niranjan',
    LocalName: 'નિરંજન',
    ShortName: 'gu-IN-NiranjanNeural',
    Gender: 'Male',
    Locale: 'gu-IN',
    LocaleName: 'Gujarati (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (he-IL, AvriNeural)',
    DisplayName: 'Avri',
    LocalName: 'אברי',
    ShortName: 'he-IL-AvriNeural',
    Gender: 'Male',
    Locale: 'he-IL',
    LocaleName: 'Hebrew (Israel)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (he-IL, HilaNeural)',
    DisplayName: 'Hila',
    LocalName: 'הילה',
    ShortName: 'he-IL-HilaNeural',
    Gender: 'Female',
    Locale: 'he-IL',
    LocaleName: 'Hebrew (Israel)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (hi-IN, MadhurNeural)',
    DisplayName: 'Madhur',
    LocalName: 'मधुर',
    ShortName: 'hi-IN-MadhurNeural',
    Gender: 'Male',
    Locale: 'hi-IN',
    LocaleName: 'Hindi (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (hi-IN, SwaraNeural)',
    DisplayName: 'Swara',
    LocalName: 'स्वरा',
    ShortName: 'hi-IN-SwaraNeural',
    Gender: 'Female',
    Locale: 'hi-IN',
    LocaleName: 'Hindi (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (hr-HR, GabrijelaNeural)',
    DisplayName: 'Gabrijela',
    LocalName: 'Gabrijela',
    ShortName: 'hr-HR-GabrijelaNeural',
    Gender: 'Female',
    Locale: 'hr-HR',
    LocaleName: 'Croatian (Croatia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (hr-HR, SreckoNeural)',
    DisplayName: 'Srecko',
    LocalName: 'Srećko',
    ShortName: 'hr-HR-SreckoNeural',
    Gender: 'Male',
    Locale: 'hr-HR',
    LocaleName: 'Croatian (Croatia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (hu-HU, NoemiNeural)',
    DisplayName: 'Noemi',
    LocalName: 'Noémi',
    ShortName: 'hu-HU-NoemiNeural',
    Gender: 'Female',
    Locale: 'hu-HU',
    LocaleName: 'Hungarian (Hungary)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (hu-HU, TamasNeural)',
    DisplayName: 'Tamas',
    LocalName: 'Tamás',
    ShortName: 'hu-HU-TamasNeural',
    Gender: 'Male',
    Locale: 'hu-HU',
    LocaleName: 'Hungarian (Hungary)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (id-ID, ArdiNeural)',
    DisplayName: 'Ardi',
    LocalName: 'Ardi',
    ShortName: 'id-ID-ArdiNeural',
    Gender: 'Male',
    Locale: 'id-ID',
    LocaleName: 'Indonesian (Indonesia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (id-ID, GadisNeural)',
    DisplayName: 'Gadis',
    LocalName: 'Gadis',
    ShortName: 'id-ID-GadisNeural',
    Gender: 'Female',
    Locale: 'id-ID',
    LocaleName: 'Indonesian (Indonesia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (it-IT, IsabellaNeural)',
    DisplayName: 'Isabella',
    LocalName: 'Isabella',
    ShortName: 'it-IT-IsabellaNeural',
    Gender: 'Female',
    Locale: 'it-IT',
    LocaleName: 'Italian (Italy)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (it-IT, DiegoNeural)',
    DisplayName: 'Diego',
    LocalName: 'Diego',
    ShortName: 'it-IT-DiegoNeural',
    Gender: 'Male',
    Locale: 'it-IT',
    LocaleName: 'Italian (Italy)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (it-IT, ElsaNeural)',
    DisplayName: 'Elsa',
    LocalName: 'Elsa',
    ShortName: 'it-IT-ElsaNeural',
    Gender: 'Female',
    Locale: 'it-IT',
    LocaleName: 'Italian (Italy)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ja-JP, NanamiNeural)',
    DisplayName: 'Nanami',
    LocalName: '七海',
    ShortName: 'ja-JP-NanamiNeural',
    Gender: 'Female',
    Locale: 'ja-JP',
    LocaleName: 'Japanese (Japan)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ja-JP, KeitaNeural)',
    DisplayName: 'Keita',
    LocalName: '圭太',
    ShortName: 'ja-JP-KeitaNeural',
    Gender: 'Male',
    Locale: 'ja-JP',
    LocaleName: 'Japanese (Japan)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (jv-ID, DimasNeural)',
    DisplayName: 'Dimas',
    LocalName: 'Dimas',
    ShortName: 'jv-ID-DimasNeural',
    Gender: 'Male',
    Locale: 'jv-ID',
    LocaleName: 'Javanese (Indonesia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (jv-ID, SitiNeural)',
    DisplayName: 'Siti',
    LocalName: 'Siti',
    ShortName: 'jv-ID-SitiNeural',
    Gender: 'Female',
    Locale: 'jv-ID',
    LocaleName: 'Javanese (Indonesia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (km-KH, PisethNeural)',
    DisplayName: 'Piseth',
    LocalName: 'ពិសិដ្ឋ',
    ShortName: 'km-KH-PisethNeural',
    Gender: 'Male',
    Locale: 'km-KH',
    LocaleName: 'Khmer (Cambodia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (km-KH, SreymomNeural)',
    DisplayName: 'Sreymom',
    LocalName: 'ស្រីមុំ',
    ShortName: 'km-KH-SreymomNeural',
    Gender: 'Female',
    Locale: 'km-KH',
    LocaleName: 'Khmer (Cambodia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ko-KR, SunHiNeural)',
    DisplayName: 'Sun-Hi',
    LocalName: '선히',
    ShortName: 'ko-KR-SunHiNeural',
    Gender: 'Female',
    Locale: 'ko-KR',
    LocaleName: 'Korean (Korea)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ko-KR, InJoonNeural)',
    DisplayName: 'InJoon',
    LocalName: '인준',
    ShortName: 'ko-KR-InJoonNeural',
    Gender: 'Male',
    Locale: 'ko-KR',
    LocaleName: 'Korean (Korea)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (lt-LT, LeonasNeural)',
    DisplayName: 'Leonas',
    LocalName: 'Leonas',
    ShortName: 'lt-LT-LeonasNeural',
    Gender: 'Male',
    Locale: 'lt-LT',
    LocaleName: 'Lithuanian (Lithuania)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (lt-LT, OnaNeural)',
    DisplayName: 'Ona',
    LocalName: 'Ona',
    ShortName: 'lt-LT-OnaNeural',
    Gender: 'Female',
    Locale: 'lt-LT',
    LocaleName: 'Lithuanian (Lithuania)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (lv-LV, EveritaNeural)',
    DisplayName: 'Everita',
    LocalName: 'Everita',
    ShortName: 'lv-LV-EveritaNeural',
    Gender: 'Female',
    Locale: 'lv-LV',
    LocaleName: 'Latvian (Latvia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (lv-LV, NilsNeural)',
    DisplayName: 'Nils',
    LocalName: 'Nils',
    ShortName: 'lv-LV-NilsNeural',
    Gender: 'Male',
    Locale: 'lv-LV',
    LocaleName: 'Latvian (Latvia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (mr-IN, AarohiNeural)',
    DisplayName: 'Aarohi',
    LocalName: 'आरोही',
    ShortName: 'mr-IN-AarohiNeural',
    Gender: 'Female',
    Locale: 'mr-IN',
    LocaleName: 'Marathi (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (mr-IN, ManoharNeural)',
    DisplayName: 'Manohar',
    LocalName: 'मनोहर',
    ShortName: 'mr-IN-ManoharNeural',
    Gender: 'Male',
    Locale: 'mr-IN',
    LocaleName: 'Marathi (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ms-MY, OsmanNeural)',
    DisplayName: 'Osman',
    LocalName: 'Osman',
    ShortName: 'ms-MY-OsmanNeural',
    Gender: 'Male',
    Locale: 'ms-MY',
    LocaleName: 'Malay (Malaysia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ms-MY, YasminNeural)',
    DisplayName: 'Yasmin',
    LocalName: 'Yasmin',
    ShortName: 'ms-MY-YasminNeural',
    Gender: 'Female',
    Locale: 'ms-MY',
    LocaleName: 'Malay (Malaysia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (mt-MT, GraceNeural)',
    DisplayName: 'Grace',
    LocalName: 'Grace',
    ShortName: 'mt-MT-GraceNeural',
    Gender: 'Female',
    Locale: 'mt-MT',
    LocaleName: 'Maltese (Malta)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (mt-MT, JosephNeural)',
    DisplayName: 'Joseph',
    LocalName: 'Joseph',
    ShortName: 'mt-MT-JosephNeural',
    Gender: 'Male',
    Locale: 'mt-MT',
    LocaleName: 'Maltese (Malta)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (my-MM, NilarNeural)',
    DisplayName: 'Nilar',
    LocalName: 'နီလာ',
    ShortName: 'my-MM-NilarNeural',
    Gender: 'Female',
    Locale: 'my-MM',
    LocaleName: 'Burmese (Myanmar)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (my-MM, ThihaNeural)',
    DisplayName: 'Thiha',
    LocalName: 'သီဟ',
    ShortName: 'my-MM-ThihaNeural',
    Gender: 'Male',
    Locale: 'my-MM',
    LocaleName: 'Burmese (Myanmar)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (nb-NO, PernilleNeural)',
    DisplayName: 'Pernille',
    LocalName: 'Pernille',
    ShortName: 'nb-NO-PernilleNeural',
    Gender: 'Female',
    Locale: 'nb-NO',
    LocaleName: 'Norwegian (Bokmål, Norway)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (nb-NO, FinnNeural)',
    DisplayName: 'Finn',
    LocalName: 'Finn',
    ShortName: 'nb-NO-FinnNeural',
    Gender: 'Male',
    Locale: 'nb-NO',
    LocaleName: 'Norwegian (Bokmål, Norway)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (nb-NO, IselinNeural)',
    DisplayName: 'Iselin',
    LocalName: 'Iselin',
    ShortName: 'nb-NO-IselinNeural',
    Gender: 'Female',
    Locale: 'nb-NO',
    LocaleName: 'Norwegian (Bokmål, Norway)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (nl-BE, ArnaudNeural)',
    DisplayName: 'Arnaud',
    LocalName: 'Arnaud',
    ShortName: 'nl-BE-ArnaudNeural',
    Gender: 'Male',
    Locale: 'nl-BE',
    LocaleName: 'Dutch (Belgium)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (nl-BE, DenaNeural)',
    DisplayName: 'Dena',
    LocalName: 'Dena',
    ShortName: 'nl-BE-DenaNeural',
    Gender: 'Female',
    Locale: 'nl-BE',
    LocaleName: 'Dutch (Belgium)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (nl-NL, ColetteNeural)',
    DisplayName: 'Colette',
    LocalName: 'Colette',
    ShortName: 'nl-NL-ColetteNeural',
    Gender: 'Female',
    Locale: 'nl-NL',
    LocaleName: 'Dutch (Netherlands)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (nl-NL, FennaNeural)',
    DisplayName: 'Fenna',
    LocalName: 'Fenna',
    ShortName: 'nl-NL-FennaNeural',
    Gender: 'Female',
    Locale: 'nl-NL',
    LocaleName: 'Dutch (Netherlands)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (nl-NL, MaartenNeural)',
    DisplayName: 'Maarten',
    LocalName: 'Maarten',
    ShortName: 'nl-NL-MaartenNeural',
    Gender: 'Male',
    Locale: 'nl-NL',
    LocaleName: 'Dutch (Netherlands)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (pl-PL, AgnieszkaNeural)',
    DisplayName: 'Agnieszka',
    LocalName: 'Agnieszka',
    ShortName: 'pl-PL-AgnieszkaNeural',
    Gender: 'Female',
    Locale: 'pl-PL',
    LocaleName: 'Polish (Poland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (pl-PL, MarekNeural)',
    DisplayName: 'Marek',
    LocalName: 'Marek',
    ShortName: 'pl-PL-MarekNeural',
    Gender: 'Male',
    Locale: 'pl-PL',
    LocaleName: 'Polish (Poland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (pl-PL, ZofiaNeural)',
    DisplayName: 'Zofia',
    LocalName: 'Zofia',
    ShortName: 'pl-PL-ZofiaNeural',
    Gender: 'Female',
    Locale: 'pl-PL',
    LocaleName: 'Polish (Poland)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (pt-BR, FranciscaNeural)',
    DisplayName: 'Francisca',
    LocalName: 'Francisca',
    ShortName: 'pt-BR-FranciscaNeural',
    Gender: 'Female',
    Locale: 'pt-BR',
    LocaleName: 'Portuguese (Brazil)',
    StyleList: [
      'calm'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (pt-BR, AntonioNeural)',
    DisplayName: 'Antonio',
    LocalName: 'Antônio',
    ShortName: 'pt-BR-AntonioNeural',
    Gender: 'Male',
    Locale: 'pt-BR',
    LocaleName: 'Portuguese (Brazil)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (pt-PT, DuarteNeural)',
    DisplayName: 'Duarte',
    LocalName: 'Duarte',
    ShortName: 'pt-PT-DuarteNeural',
    Gender: 'Male',
    Locale: 'pt-PT',
    LocaleName: 'Portuguese (Portugal)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (pt-PT, FernandaNeural)',
    DisplayName: 'Fernanda',
    LocalName: 'Fernanda',
    ShortName: 'pt-PT-FernandaNeural',
    Gender: 'Female',
    Locale: 'pt-PT',
    LocaleName: 'Portuguese (Portugal)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (pt-PT, RaquelNeural)',
    DisplayName: 'Raquel',
    LocalName: 'Raquel',
    ShortName: 'pt-PT-RaquelNeural',
    Gender: 'Female',
    Locale: 'pt-PT',
    LocaleName: 'Portuguese (Portugal)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ro-RO, AlinaNeural)',
    DisplayName: 'Alina',
    LocalName: 'Alina',
    ShortName: 'ro-RO-AlinaNeural',
    Gender: 'Female',
    Locale: 'ro-RO',
    LocaleName: 'Romanian (Romania)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ro-RO, EmilNeural)',
    DisplayName: 'Emil',
    LocalName: 'Emil',
    ShortName: 'ro-RO-EmilNeural',
    Gender: 'Male',
    Locale: 'ro-RO',
    LocaleName: 'Romanian (Romania)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ru-RU, SvetlanaNeural)',
    DisplayName: 'Svetlana',
    LocalName: 'Светлана',
    ShortName: 'ru-RU-SvetlanaNeural',
    Gender: 'Female',
    Locale: 'ru-RU',
    LocaleName: 'Russian (Russia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ru-RU, DariyaNeural)',
    DisplayName: 'Dariya',
    LocalName: 'Дария',
    ShortName: 'ru-RU-DariyaNeural',
    Gender: 'Female',
    Locale: 'ru-RU',
    LocaleName: 'Russian (Russia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ru-RU, DmitryNeural)',
    DisplayName: 'Dmitry',
    LocalName: 'Дмитрий',
    ShortName: 'ru-RU-DmitryNeural',
    Gender: 'Male',
    Locale: 'ru-RU',
    LocaleName: 'Russian (Russia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (sk-SK, LukasNeural)',
    DisplayName: 'Lukas',
    LocalName: 'Lukáš',
    ShortName: 'sk-SK-LukasNeural',
    Gender: 'Male',
    Locale: 'sk-SK',
    LocaleName: 'Slovak (Slovakia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (sk-SK, ViktoriaNeural)',
    DisplayName: 'Viktoria',
    LocalName: 'Viktória',
    ShortName: 'sk-SK-ViktoriaNeural',
    Gender: 'Female',
    Locale: 'sk-SK',
    LocaleName: 'Slovak (Slovakia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (sl-SI, PetraNeural)',
    DisplayName: 'Petra',
    LocalName: 'Petra',
    ShortName: 'sl-SI-PetraNeural',
    Gender: 'Female',
    Locale: 'sl-SI',
    LocaleName: 'Slovenian (Slovenia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (sl-SI, RokNeural)',
    DisplayName: 'Rok',
    LocalName: 'Rok',
    ShortName: 'sl-SI-RokNeural',
    Gender: 'Male',
    Locale: 'sl-SI',
    LocaleName: 'Slovenian (Slovenia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (so-SO, MuuseNeural)',
    DisplayName: 'Muuse',
    LocalName: 'Muuse',
    ShortName: 'so-SO-MuuseNeural',
    Gender: 'Male',
    Locale: 'so-SO',
    LocaleName: 'Somali (Somalia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (so-SO, UbaxNeural)',
    DisplayName: 'Ubax',
    LocalName: 'Ubax',
    ShortName: 'so-SO-UbaxNeural',
    Gender: 'Female',
    Locale: 'so-SO',
    LocaleName: 'Somali (Somalia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (su-ID, JajangNeural)',
    DisplayName: 'Jajang',
    LocalName: 'Jajang',
    ShortName: 'su-ID-JajangNeural',
    Gender: 'Male',
    Locale: 'su-ID',
    LocaleName: 'Sundanese (Indonesia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (su-ID, TutiNeural)',
    DisplayName: 'Tuti',
    LocalName: 'Tuti',
    ShortName: 'su-ID-TutiNeural',
    Gender: 'Female',
    Locale: 'su-ID',
    LocaleName: 'Sundanese (Indonesia)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (sv-SE, SofieNeural)',
    DisplayName: 'Sofie',
    LocalName: 'Sofie',
    ShortName: 'sv-SE-SofieNeural',
    Gender: 'Female',
    Locale: 'sv-SE',
    LocaleName: 'Swedish (Sweden)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (sv-SE, HilleviNeural)',
    DisplayName: 'Hillevi',
    LocalName: 'Hillevi',
    ShortName: 'sv-SE-HilleviNeural',
    Gender: 'Female',
    Locale: 'sv-SE',
    LocaleName: 'Swedish (Sweden)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (sv-SE, MattiasNeural)',
    DisplayName: 'Mattias',
    LocalName: 'Mattias',
    ShortName: 'sv-SE-MattiasNeural',
    Gender: 'Male',
    Locale: 'sv-SE',
    LocaleName: 'Swedish (Sweden)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (sw-KE, RafikiNeural)',
    DisplayName: 'Rafiki',
    LocalName: 'Rafiki',
    ShortName: 'sw-KE-RafikiNeural',
    Gender: 'Male',
    Locale: 'sw-KE',
    LocaleName: 'Swahili (Kenya)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (sw-KE, ZuriNeural)',
    DisplayName: 'Zuri',
    LocalName: 'Zuri',
    ShortName: 'sw-KE-ZuriNeural',
    Gender: 'Female',
    Locale: 'sw-KE',
    LocaleName: 'Swahili (Kenya)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (sw-TZ, DaudiNeural)',
    DisplayName: 'Daudi',
    LocalName: 'Daudi',
    ShortName: 'sw-TZ-DaudiNeural',
    Gender: 'Male',
    Locale: 'sw-TZ',
    LocaleName: 'Swahili (Tanzania)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (sw-TZ, RehemaNeural)',
    DisplayName: 'Rehema',
    LocalName: 'Rehema',
    ShortName: 'sw-TZ-RehemaNeural',
    Gender: 'Female',
    Locale: 'sw-TZ',
    LocaleName: 'Swahili (Tanzania)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ta-IN, PallaviNeural)',
    DisplayName: 'Pallavi',
    LocalName: 'பல்லவி',
    ShortName: 'ta-IN-PallaviNeural',
    Gender: 'Female',
    Locale: 'ta-IN',
    LocaleName: 'Tamil (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ta-IN, ValluvarNeural)',
    DisplayName: 'Valluvar',
    LocalName: 'வள்ளுவர்',
    ShortName: 'ta-IN-ValluvarNeural',
    Gender: 'Male',
    Locale: 'ta-IN',
    LocaleName: 'Tamil (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ta-LK, KumarNeural)',
    DisplayName: 'Kumar',
    LocalName: 'குமார்',
    ShortName: 'ta-LK-KumarNeural',
    Gender: 'Male',
    Locale: 'ta-LK',
    LocaleName: 'Tamil (Sri Lanka)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ta-LK, SaranyaNeural)',
    DisplayName: 'Saranya',
    LocalName: 'சரண்யா',
    ShortName: 'ta-LK-SaranyaNeural',
    Gender: 'Female',
    Locale: 'ta-LK',
    LocaleName: 'Tamil (Sri Lanka)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ta-SG, AnbuNeural)',
    DisplayName: 'Anbu',
    LocalName: 'அன்பு',
    ShortName: 'ta-SG-AnbuNeural',
    Gender: 'Male',
    Locale: 'ta-SG',
    LocaleName: 'Tamil (Singapore)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ta-SG, VenbaNeural)',
    DisplayName: 'Venba',
    LocalName: 'வெண்பா',
    ShortName: 'ta-SG-VenbaNeural',
    Gender: 'Female',
    Locale: 'ta-SG',
    LocaleName: 'Tamil (Singapore)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (te-IN, MohanNeural)',
    DisplayName: 'Mohan',
    LocalName: 'మోహన్',
    ShortName: 'te-IN-MohanNeural',
    Gender: 'Male',
    Locale: 'te-IN',
    LocaleName: 'Telugu (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (te-IN, ShrutiNeural)',
    DisplayName: 'Shruti',
    LocalName: 'శ్రుతి',
    ShortName: 'te-IN-ShrutiNeural',
    Gender: 'Female',
    Locale: 'te-IN',
    LocaleName: 'Telugu (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (th-TH, PremwadeeNeural)',
    DisplayName: 'Premwadee',
    LocalName: 'เปรมวดี',
    ShortName: 'th-TH-PremwadeeNeural',
    Gender: 'Female',
    Locale: 'th-TH',
    LocaleName: 'Thai (Thailand)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (th-TH, AcharaNeural)',
    DisplayName: 'Achara',
    LocalName: 'อัจฉรา',
    ShortName: 'th-TH-AcharaNeural',
    Gender: 'Female',
    Locale: 'th-TH',
    LocaleName: 'Thai (Thailand)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (th-TH, NiwatNeural)',
    DisplayName: 'Niwat',
    LocalName: 'นิวัฒน์',
    ShortName: 'th-TH-NiwatNeural',
    Gender: 'Male',
    Locale: 'th-TH',
    LocaleName: 'Thai (Thailand)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (tr-TR, AhmetNeural)',
    DisplayName: 'Ahmet',
    LocalName: 'Ahmet',
    ShortName: 'tr-TR-AhmetNeural',
    Gender: 'Male',
    Locale: 'tr-TR',
    LocaleName: 'Turkish (Turkey)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (tr-TR, EmelNeural)',
    DisplayName: 'Emel',
    LocalName: 'Emel',
    ShortName: 'tr-TR-EmelNeural',
    Gender: 'Female',
    Locale: 'tr-TR',
    LocaleName: 'Turkish (Turkey)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (uk-UA, OstapNeural)',
    DisplayName: 'Ostap',
    LocalName: 'Остап',
    ShortName: 'uk-UA-OstapNeural',
    Gender: 'Male',
    Locale: 'uk-UA',
    LocaleName: 'Ukrainian (Ukraine)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (uk-UA, PolinaNeural)',
    DisplayName: 'Polina',
    LocalName: 'Поліна',
    ShortName: 'uk-UA-PolinaNeural',
    Gender: 'Female',
    Locale: 'uk-UA',
    LocaleName: 'Ukrainian (Ukraine)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ur-IN, GulNeural)',
    DisplayName: 'Gul',
    LocalName: 'گل',
    ShortName: 'ur-IN-GulNeural',
    Gender: 'Female',
    Locale: 'ur-IN',
    LocaleName: 'Urdu (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ur-IN, SalmanNeural)',
    DisplayName: 'Salman',
    LocalName: 'سلمان',
    ShortName: 'ur-IN-SalmanNeural',
    Gender: 'Male',
    Locale: 'ur-IN',
    LocaleName: 'Urdu (India)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ur-PK, AsadNeural)',
    DisplayName: 'Asad',
    LocalName: 'اسد',
    ShortName: 'ur-PK-AsadNeural',
    Gender: 'Male',
    Locale: 'ur-PK',
    LocaleName: 'Urdu (Pakistan)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (ur-PK, UzmaNeural)',
    DisplayName: 'Uzma',
    LocalName: 'عظمیٰ',
    ShortName: 'ur-PK-UzmaNeural',
    Gender: 'Female',
    Locale: 'ur-PK',
    LocaleName: 'Urdu (Pakistan)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (uz-UZ, MadinaNeural)',
    DisplayName: 'Madina',
    LocalName: 'Madina',
    ShortName: 'uz-UZ-MadinaNeural',
    Gender: 'Female',
    Locale: 'uz-UZ',
    LocaleName: 'Uzbek (Uzbekistan)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (uz-UZ, SardorNeural)',
    DisplayName: 'Sardor',
    LocalName: 'Sardor',
    ShortName: 'uz-UZ-SardorNeural',
    Gender: 'Male',
    Locale: 'uz-UZ',
    LocaleName: 'Uzbek (Uzbekistan)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (vi-VN, HoaiMyNeural)',
    DisplayName: 'HoaiMy',
    LocalName: 'Hoài My',
    ShortName: 'vi-VN-HoaiMyNeural',
    Gender: 'Female',
    Locale: 'vi-VN',
    LocaleName: 'Vietnamese (Vietnam)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (vi-VN, NamMinhNeural)',
    DisplayName: 'NamMinh',
    LocalName: 'Nam Minh',
    ShortName: 'vi-VN-NamMinhNeural',
    Gender: 'Male',
    Locale: 'vi-VN',
    LocaleName: 'Vietnamese (Vietnam)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoxiaoNeural)',
    DisplayName: 'Xiaoxiao',
    LocalName: '晓晓',
    ShortName: 'zh-CN-XiaoxiaoNeural',
    Gender: 'Female',
    Locale: 'zh-CN',
    LocaleName: 'Chinese (Mandarin, Simplified)',
    StyleList: [
      'assistant',
      'chat',
      'customerservice',
      'newscast',
      'affectionate',
      'angry',
      'calm',
      'cheerful',
      'disgruntled',
      'fearful',
      'gentle',
      'lyrical',
      'sad',
      'serious'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-CN, YunyangNeural)',
    DisplayName: 'Yunyang',
    LocalName: '云扬',
    ShortName: 'zh-CN-YunyangNeural',
    Gender: 'Male',
    Locale: 'zh-CN',
    LocaleName: 'Chinese (Mandarin, Simplified)',
    StyleList: [
      'customerservice',
      'narration'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-CN, XiaohanNeural)',
    DisplayName: 'Xiaohan',
    LocalName: '晓涵',
    ShortName: 'zh-CN-XiaohanNeural',
    Gender: 'Female',
    Locale: 'zh-CN',
    LocaleName: 'Chinese (Mandarin, Simplified)',
    StyleList: [
      'calm',
      'fearful',
      'cheerful',
      'disgruntled',
      'serious',
      'angry',
      'sad',
      'gentle',
      'affectionate',
      'embarrassed'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-CN, XiaomoNeural)',
    DisplayName: 'Xiaomo',
    LocalName: '晓墨',
    ShortName: 'zh-CN-XiaomoNeural',
    Gender: 'Female',
    Locale: 'zh-CN',
    LocaleName: 'Chinese (Mandarin, Simplified)',
    StyleList: [
      'embarrassed',
      'calm',
      'fearful',
      'cheerful',
      'disgruntled',
      'serious',
      'angry',
      'sad',
      'depressed',
      'affectionate',
      'gentle',
      'Envy'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA',
    RolePlayList: [
      'YoungAdultFemale',
      'YoungAdultMale',
      'OlderAdultFemale',
      'OlderAdultMale',
      'SeniorFemale',
      'SeniorMale',
      'Girl',
      'Boy'
    ]
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoruiNeural)',
    DisplayName: 'Xiaorui',
    LocalName: '晓睿',
    ShortName: 'zh-CN-XiaoruiNeural',
    Gender: 'Female',
    Locale: 'zh-CN',
    LocaleName: 'Chinese (Mandarin, Simplified)',
    StyleList: [
      'calm',
      'fearful',
      'angry',
      'sad'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoxuanNeural)',
    DisplayName: 'Xiaoxuan',
    LocalName: '晓萱',
    ShortName: 'zh-CN-XiaoxuanNeural',
    Gender: 'Female',
    Locale: 'zh-CN',
    LocaleName: 'Chinese (Mandarin, Simplified)',
    StyleList: [
      'calm',
      'fearful',
      'cheerful',
      'disgruntled',
      'serious',
      'angry',
      'gentle',
      'depressed'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA',
    RolePlayList: [
      'YoungAdultFemale',
      'YoungAdultMale',
      'OlderAdultFemale',
      'OlderAdultMale',
      'SeniorFemale',
      'SeniorMale',
      'Girl',
      'Boy'
    ]
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)',
    DisplayName: 'Xiaoyou',
    LocalName: '晓悠',
    ShortName: 'zh-CN-XiaoyouNeural',
    Gender: 'Female',
    Locale: 'zh-CN',
    LocaleName: 'Chinese (Mandarin, Simplified)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-CN, YunxiNeural)',
    DisplayName: 'Yunxi',
    LocalName: '云希',
    ShortName: 'zh-CN-YunxiNeural',
    Gender: 'Male',
    Locale: 'zh-CN',
    LocaleName: 'Chinese (Mandarin, Simplified)',
    StyleList: [
      'embarrassed',
      'calm',
      'fearful',
      'cheerful',
      'disgruntled',
      'serious',
      'angry',
      'sad',
      'depressed',
      'assistant'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-CN, YunyeNeural)',
    DisplayName: 'Yunye',
    LocalName: '云野',
    ShortName: 'zh-CN-YunyeNeural',
    Gender: 'Male',
    Locale: 'zh-CN',
    LocaleName: 'Chinese (Mandarin, Simplified)',
    StyleList: [
      'embarrassed',
      'calm',
      'fearful',
      'cheerful',
      'disgruntled',
      'serious',
      'angry',
      'sad',
      'depressed',
      'affectionate',
      'gentle',
      'Envy'
    ],
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA',
    RolePlayList: [
      'YoungAdultFemale',
      'YoungAdultMale',
      'OlderAdultFemale',
      'OlderAdultMale',
      'SeniorFemale',
      'SeniorMale',
      'Girl',
      'Boy'
    ]
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-HK, HiuMaanNeural)',
    DisplayName: 'HiuMaan',
    LocalName: '曉曼',
    ShortName: 'zh-HK-HiuMaanNeural',
    Gender: 'Female',
    Locale: 'zh-HK',
    LocaleName: 'Chinese (Cantonese, Traditional)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-HK, HiuGaaiNeural)',
    DisplayName: 'HiuGaai',
    LocalName: '曉佳',
    ShortName: 'zh-HK-HiuGaaiNeural',
    Gender: 'Female',
    Locale: 'zh-HK',
    LocaleName: 'Chinese (Cantonese, Traditional)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-HK, WanLungNeural)',
    DisplayName: 'WanLung',
    LocalName: '雲龍',
    ShortName: 'zh-HK-WanLungNeural',
    Gender: 'Male',
    Locale: 'zh-HK',
    LocaleName: 'Chinese (Cantonese, Traditional)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-TW, HsiaoChenNeural)',
    DisplayName: 'HsiaoChen',
    LocalName: '曉臻',
    ShortName: 'zh-TW-HsiaoChenNeural',
    Gender: 'Female',
    Locale: 'zh-TW',
    LocaleName: 'Chinese (Taiwanese Mandarin)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-TW, HsiaoYuNeural)',
    DisplayName: 'HsiaoYu',
    LocalName: '曉雨',
    ShortName: 'zh-TW-HsiaoYuNeural',
    Gender: 'Female',
    Locale: 'zh-TW',
    LocaleName: 'Chinese (Taiwanese Mandarin)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zh-TW, YunJheNeural)',
    DisplayName: 'YunJhe',
    LocalName: '雲哲',
    ShortName: 'zh-TW-YunJheNeural',
    Gender: 'Male',
    Locale: 'zh-TW',
    LocaleName: 'Chinese (Taiwanese Mandarin)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zu-ZA, ThandoNeural)',
    DisplayName: 'Thando',
    LocalName: 'Thando',
    ShortName: 'zu-ZA-ThandoNeural',
    Gender: 'Female',
    Locale: 'zu-ZA',
    LocaleName: 'Zulu (South Africa)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
  {
    Name: 'Microsoft Server Speech Text to Speech Voice (zu-ZA, ThembaNeural)',
    DisplayName: 'Themba',
    LocalName: 'Themba',
    ShortName: 'zu-ZA-ThembaNeural',
    Gender: 'Male',
    Locale: 'zu-ZA',
    LocaleName: 'Zulu (South Africa)',
    SampleRateHertz: '24000',
    VoiceType: 'Neural',
    Status: 'GA'
  },
];

let SpeechSynthesisLanguageMicrosoft = [];

rawData.forEach((data) => {
  const lang = SpeechSynthesisLanguageMicrosoft.find((l) => {
    return l.code === data.Locale;
  });

  if (!lang) {
    SpeechSynthesisLanguageMicrosoft.push({
      code: data.Locale,
      name: data.LocaleName,
      voices: rawData.filter((d) => {
        return d.Locale === data.Locale;
      }).map((d) => {
        return {
          value: d.ShortName,
          name: `${d.DisplayName} (${d.Gender})`,
        };
      }),
    });
  }
});

export default SpeechSynthesisLanguageMicrosoft;